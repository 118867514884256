import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="blog" link="http://blog.wccmw.xyz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  记录前端工作笔记、学习总结、生活
    </ProjectCard>
    <ProjectCard title="next_github" link="https://github.com/cc7gs/next_github" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)" mdxType="ProjectCard">
next github demo
    </ProjectCard>
    <ProjectCard title="react_workshop" link="https://github.com/cc7gs/react-workshop" bg="linear-gradient(to right, rgb(213, 133, 255) 0%, rgb(0, 255, 238) 100%)" mdxType="ProjectCard">
learn frontendMaster react note
    </ProjectCard>
    <ProjectCard title="Software-notes" link="https://github.com/cc7gs/Software-notes" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
  软考复习总结
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      